var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "pricing", "fill-height": "", tag: "section" } },
    [
      _c(
        "v-row",
        {
          staticClass: "text-center",
          attrs: { align: "center", justify: "center" }
        },
        [
          _c(
            "v-col",
            { staticClass: "mb-10", attrs: { cols: "12" } },
            [
              _c("pages-heading", { staticClass: "mb-12" }, [
                _vm._v("\n        Pick the best plan for you\n      ")
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "display-1 font-weight-light grey--text text--lighten-2"
                },
                [
                  _vm._v(
                    "\n        You have Free Unlimited Updates and Premium Support on "
                  ),
                  _c("br", { staticClass: "hidden-sm-and-down" }),
                  _vm._v("each package.\n      ")
                ]
              )
            ],
            1
          ),
          _vm._l(_vm.plans, function(plan, i) {
            return [
              _c(
                "v-col",
                {
                  key: i,
                  staticClass: "d-flex",
                  attrs: { cols: "12", sm: "6", md: "3" }
                },
                [_c("pages-plan-card", { attrs: { plan: plan } })],
                1
              ),
              i === 1 &&
              _vm.$vuetify.breakpoint.smAndDown &&
              i + 1 !== _vm.plans.length
                ? _c(
                    "v-col",
                    {
                      key: "divider-" + i,
                      staticClass: "pa-6",
                      attrs: { cols: "12" }
                    },
                    [_c("v-divider")],
                    1
                  )
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }